import React, { Component } from 'react';

class ThreadingPrices extends Component {
  render() {
    return (
      <table className="table">
        <tbody>
        <tr>
          <th colSpan={2} className="has-text-centered">Threading</th>
        </tr>
        <tr>
          <th scope="row">Eyebrows</th>
          <td>$15</td>
        </tr>
        <tr>
          <th scope="row">Lips/Chin</th>
          <td>$10</td>
        </tr>
        <tr>
          <th scope="row">Full Face</th>
          <td>$35</td>
        </tr>
        <tr>
          <th colSpan={2} className="has-text-centered">Eyebrows</th>
        </tr>
        <tr>
          <th scope="row">Eyebrows Wax</th>
          <td>$15</td>
        </tr>
        <tr>
          <th scope="row">Eyebrows Tin</th>
          <td>$25</td>
        </tr>
        <tr>
          <th scope="row">Upper Eyeliners 3D Microblading</th>
          <td>$350</td>
        </tr>
        <tr>
          <th scope="row">Eyebrows 3D Microblading</th>
          <td>$450 (Initial), $250 (Touch-up)</td>
        </tr>
        <tr>
          <th colSpan={2} className="has-text-centered">Eyelashes</th>
        </tr>
        <tr>
          <th scope="row">Eyelashes Tin</th>
          <td>$50</td>
        </tr>
        <tr>
          <th scope="row">Eyelashes Fill</th>
          <td>$40+</td>
        </tr>
        <tr>
          <th scope="row">Eyelashes Perm</th>
          <td>$50</td>
        </tr>
        <tr>
          <th scope="row">Eyelashes Extension (Individual)</th>
          <td>$120&ndash;150</td>
        </tr>
        </tbody>
      </table>
    );
  }
}

export default ThreadingPrices;
