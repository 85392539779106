import React, { Component } from 'react';
import './index.css';

class Map extends Component {
  render() {
    return (
      <div className="Map-frame">
        <iframe title="google maps" src={this.props.url} frameBorder={0} allowFullScreen={true}></iframe>
      </div>
    );
  }
}

export default Map;