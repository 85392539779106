import React, { Component } from 'react';

class ExtensionPrices extends Component {
  render() {
    return (
      <table className="table">
        <tbody>
        <tr>
          <th scope="row">Japanese Straightening</th>
          <td>$200&ndash;500</td>
        </tr>
        <tr>
          <th scope="row">Permanent Waving</th>
          <td>$100&ndash;200</td>
        </tr>
        <tr>
          <th scope="row">Textures / Relaxers</th>
          <td>$85&ndash;110</td>
        </tr>
        <tr>
          <th scope="row">Deep Conditioning Treatment</th>
          <td>$25&ndash;40</td>
        </tr>
        <tr>
          <th scope="row">Brazilian Keratin Treatment</th>
          <td>$200&ndash;400</td>
        </tr>
        <tr>
          <th scope="row">Hair Extensions</th>
          <td>$200&ndash;800</td>
        </tr>
        <tr>
          <th scope="row">Hair Extensions (Individual Tracks)</th>
          <td>$95 per track</td>
        </tr>
        </tbody>
      </table>
    );
  }
}

export default ExtensionPrices;
