import React, { Component } from 'react';
import kevinmurphy from './kevin-murphy-products.jpg';

class KevinMurphy extends Component {
  render() {
    return (
      <div className="box">
        <article className="media">
          <div className="media-left is-hidden-mobile">
            <figure className="image">
              <img src={kevinmurphy} alt="kevin murphy" />
            </figure>
          </div>
          <div className="media-content">
            <div className="content">
              <h1 className="title">Skincare for your hair. Performance, strength and longevity.</h1>
              <h3 className="subtitle">Vitamins and amino acids repair the outer surface of the hair, thickening the hair and reducing the formation of split ends. Moisturisers from Mango butter and Murumuru butter smooth the cuticles and soften the hair. Extracts from Peruvian Bark, Baobab, Bamboo and Orange Blossom infuse the hair with weightless moisture. Antioxidants from Kakadu Plum, Orchid, Lotus Flower and Desert Lime reduce breakage and repair chemical damage naturally. All KEVIN.MURPHY hair products are sulphate free, paraben free and cruelty free.</h3>
            </div>
          </div>
        </article>
      </div>
    );
  }
}

export default KevinMurphy;