import React, { Component } from 'react';

class WaxingPrices extends Component {
  render() {
    return (
      <table className="table">
        <tbody>
        <tr>
          <th scope="row">Lips/Chin</th>
          <td>$10</td>
        </tr>
        <tr>
          <th scope="row">Shoulders</th>
          <td>$20</td>
        </tr>
        <tr>
          <th scope="row">Underarms</th>
          <td>$25</td>
        </tr>
        <tr>
          <th scope="row">Half/Full Arms</th>
          <td>$20 (Half), $35 (Full)</td>
        </tr>
        <tr>
          <th scope="row">Chest</th>
          <td>$35</td>
        </tr>
        <tr>
          <th scope="row">Back</th>
          <td>$50&ndash;60</td>
        </tr>
        <tr>
          <th scope="row">Brazilian Bikini</th>
          <td>$60&ndash;70</td>
        </tr>
        <tr>
          <th scope="row">Regular Bikini</th>
          <td>$25</td>
        </tr>
        <tr>
          <th scope="row">Full Legs</th>
          <td>$75</td>
        </tr>
        <tr>
          <th scope="row">Half Legs</th>
          <td>$36</td>
        </tr>
        <tr>
          <th scope="row">Full Legs &amp; Regular Bikini</th>
          <td>$75</td>
        </tr>
        <tr>
          <th scope="row">Half Legs &amp; Regular Bikini</th>
          <td>$60</td>
        </tr>
        </tbody>
      </table>
    );
  }
}

export default WaxingPrices;
