import React, { Component } from 'react';
import './App.css';
import './fontawesome/fontawesome-all.js';
import CoverHero from './CoverHero';
import Features from './Features';
import Aveda from './Aveda';
import KevinMurphy from './KevinMurphy';
import GiftCards from './GiftCards';
import Services from './Services';
import Locations from './Locations';
import ContactForm from './ContactForm';
import Footer from './Footer';

class App extends Component {
  render() {
    return (
      <div className="App">
        <CoverHero />
        <Features />
        <Aveda />
        <KevinMurphy />
        <GiftCards />
        <Services />
        <Locations />
        <ContactForm />
        <Footer />
      </div>
    );
  }
}

export default App;
