import React, { Component } from 'react';
import './index.css';
import logo from './logo.png';
import hero from './hero-background.jpg';
import hero1 from './hero-background-1.jpg';

class CoverHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heroImage: {
        backgroundImage: 'url(' + hero + ')'
      }
    }

    this.imgUrls = [hero, hero1];
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState({
        heroImage: {
          backgroundImage: 'url(' + this.imgUrls[Math.floor(Math.random()*this.imgUrls.length)] + ')'
        }
      });
    }, 6000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  ga = (index, event) => {
    event.preventDefault();
    const url = event.currentTarget.href;

    let icon;
    switch (index) {
      case 0:
        icon = 'Facebook';
        break;
      case 1:
        icon = 'Twitter';
        break;
      default:
        icon = 'Undefined';
    }

    window.ga('send', 'event', 'Social Media Icon', 'click', icon, {
      hitCallback: function() {
        window.location.href = url;
      }
    });
  };

  render() {
    return (
      <div className="hero is-large CoverHero" style={this.state.heroImage}>
        <div className="hero-head">
          <nav className="navbar" aria-label="main navigation">
            <div className="navbar-brand">
              <a className="navbar-item" href="/"><img src={logo} alt="Logo" /></a>
              <a className="navbar-item is-hidden-desktop" href="https://www.facebook.com/isalonnyc" onClick={(e) => this.ga(0, e)}><i className="fab fa-facebook-f"></i></a>
              <a className="navbar-item is-hidden-desktop" href="https://twitter.com/isalonnyc" onClick={(e) => this.ga(1, e)}><i className="fab fa-twitter"></i></a>
            </div>
            <div className="navbar-menu">
              <div className="navbar-end">
                <a className="navbar-item" href="https://www.facebook.com/isalonnyc" onClick={(e) => this.ga(0, e)}><i className="fab fa-facebook-f"></i></a>
                <a className="navbar-item" href="https://twitter.com/isalonnyc" onClick={(e) => this.ga(1, e)}><i className="fab fa-twitter"></i></a>
              </div>
            </div>
          </nav>
        </div>
        <div className="hero-body CoverHero-body">
          <div className="container has-text-centered">
            <h1 className="title">Beauty Salon. Re-engineered Elegance.</h1>
            <h2 className="subtitle">Specializing in hair color and treatments</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default CoverHero;
