import React, { Component } from 'react';

class ColorPrices extends Component {
  render() {
    return (
      <table className="table">
        <thead>
        <tr>
          <th></th>
          <th>Master Stylist</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">Balayage Color</th>
          <td>$175+</td>
        </tr>
        <tr>
          <th scope="row">Highlights (Partial)</th>
          <td>$140+</td>
        </tr>
        <tr>
          <th scope="row">Highlights (Full)</th>
          <td>$200+</td>
        </tr>
        <tr>
          <th scope="row">Single Process Color</th>
          <td>$100+</td>
        </tr>
        <tr>
          <th scope="row">Double Process Color</th>
          <td>$180+</td>
        </tr>
        <tr>
          <th scope="row">Organic Color</th>
          <td>$100&ndash;120</td>
        </tr>
        </tbody>
      </table>
    );
  }
}

export default ColorPrices;
