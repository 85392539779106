import React, { Component } from 'react';
import aveda from './aveda-products.jpg';

class Aveda extends Component {
  render() {
    return (
      <div className="box">
        <article className="media">
          <div className="media-content">
            <div className="content">
              <h1 className="title">Over 95% naturally derived. Ingredients harvested worldwide.</h1>
              <h3 className="subtitle">While we specialize in hair care, we offer Aveda products. Their products are all professionally developed, clinically tested, and go to exceptional lengths to be respectful of the earth.</h3>
            </div>
          </div>
          <div className="media-right is-hidden-mobile">
            <figure className="image">
              <img src={aveda} alt="aveda" />
            </figure>
          </div>
        </article>
      </div>
    );
  }
}

export default Aveda;