import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div className="content has-text-white has-text-centered">&copy; {new Date().getFullYear()} I Salon. Made in NYC.</div>
    );
  }
}

export default Footer;