import React, { Component } from 'react';
import './index.css';
import hairdryer from './hair-dryer.png';
import orchid from './orchid.png';

class Features extends Component {
  render() {
    return (
      <div className="hero Features-hero">
        <div className="hero-body">
          <div className="columns">
            <div className="column">
              <div className="content has-text-centered">
                <img src={hairdryer} alt="hair dryer" />
                <h4 className="has-text-white">Hair Care</h4>
                <p className="has-text-white">Our extensive Hair services include not only our usual excellent Haircuts, Highlights, and Extensions, but also such specialties as Organic Coloring, Japanese Straightening, and Brazilian Keratin Treatment.</p>
              </div>
            </div>
            <div className="column">
              <div className="content has-text-centered">
                <img src={orchid} alt="massage" />
                <h4 className="has-text-white">Massage</h4>
                <p className="has-text-white">These days, we need to give ourselves a chance to relax. Indulge yourself in a rejuvenating session from as little as 10 to 30 minutes.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Features;
