import React, { Component } from 'react';

class Details extends Component {
  ga = (index) => {
    let location;
    switch (index) {
      case 0:
        location = 'Alphabet City';
        break;
      case 1:
        location = 'World Trade Center';
        break;
      case 2:
        location = 'Astor Place';
        break;
      default:
        location = 'Undefined';
    }

    window.ga('send', 'event', 'Book Appointment', 'click', location);
  };

  render() {
    return (
      <div>
        <div className="columns">
          <div className="column is-4">
          <div className="content">
            <h4>Astor Place</h4>
            <p>Conveniently located around the corner of 10th Street and 4th Avenue with its storefront on 10th Street. Closest train stops &mdash; Astor Place [6] and 8 St - NYU [N,R]</p>
            <div className="columns is-mobile">
              <div className="column is-1">
                <p><i className="fas fa-map-marker-alt"></i></p>
              </div>
              <div className="column is-11">
                <p>77 East 10th Street<br />New York, NY 10003</p>
                {this.props.currentMap !== 0 && <button className="button is-rounded" onClick={() => this.props.changeMap(0)}>Show map</button>}
              </div>
            </div>
            <div className="columns is-mobile">
              <div className="column is-1">
                <p><i className="fas fa-phone"></i></p>
              </div>
              <div className="column is-11">
                <p>212-505-0537</p>
              </div>
            </div>
            <div className="columns is-mobile">
              <div className="column is-1">
                <p><i className="far fa-clock"></i></p>
              </div>
              <div className="column is-11">
                <p>Monday&ndash;Friday 11 AM&ndash;7 PM<br />Saturday 11 AM&ndash;6 PM</p>
              </div>
            </div>
            <div className="columns is-mobile">
              <div className="column is-1"></div>
              <div className="column is-11">
                <a className="button is-info" href="https://squareup.com/appointments/book/3S1RT4K1JWHVP/i-salon-astor-place-new-york-ny" target="_blank" rel="noopener noreferrer" onClick={() => this.ga(2)}>Book appointment&nbsp;<i className="fas fa-external-link-alt"></i></a>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default Details;
