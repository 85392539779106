import React, { Component } from 'react';
import HairPrices from './HairPrices';
import ColorPrices from './ColorPrices';
import ExtensionPrices from './ExtensionPrices';
import WaxingPrices from './WaxingPrices';
import ThreadingPrices from './ThreadingPrices';
import MassagePrices from './MassagePrices';
import NailPrices from './NailPrices';

class PricingTable extends Component {
  render() {
    switch (this.props.type) {
      case 0:
        return <HairPrices />;
      case 1:
        return <ColorPrices />;
      case 2:
        return <ExtensionPrices />;
      case 3:
        return <WaxingPrices />;
      case 4:
        return <ThreadingPrices />;
      case 5:
        return <MassagePrices />;
      case 6:
        return <NailPrices />;
      default:
        return <HairPrices />;
    }
  }
}

export default PricingTable;
