import React, { Component } from 'react';

class MassagePrices extends Component {
  render() {
    return (
      <table className="table">
        <tbody>
        <tr>
          <th scope="row">10 mins</th>
          <td>$15</td>
        </tr>
        <tr>
          <th scope="row">20 mins</th>
          <td>$25</td>
        </tr>
        <tr>
          <th scope="row">30 mins</th>
          <td>$35</td>
        </tr>
        </tbody>
      </table>
    );
  }
}

export default MassagePrices;