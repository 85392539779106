import React, { Component } from 'react';

class NailPrices extends Component {
  render() {
    return (
      <table className="table">
        <tbody>
        <tr>
          <th colSpan={2} className="has-text-centered">Nails</th>
        </tr>
        <tr>
          <th scope="row">Manicure</th>
          <td>$13</td>
        </tr>
        <tr>
          <th scope="row">French Manicure</th>
          <td>$16</td>
        </tr>
        <tr>
          <th scope="row">Spa Manicure</th>
          <td>$25</td>
        </tr>
        <tr>
          <th scope="row">Paraffin Manicure</th>
          <td>$25</td>
        </tr>
        <tr>
          <th scope="row">Buff Manicure</th>
          <td>$17</td>
        </tr>
        <tr>
          <th scope="row">Gel Manicure</th>
          <td>$50</td>
        </tr>
        <tr>
          <th scope="row">SNS Powder Dip Manicure</th>
          <td>$45+</td>
        </tr>
        <tr>
          <th colSpan={2} className="has-text-centered">Polish Change</th>
        </tr>
        <tr>
          <th scope="row">Hands</th>
          <td>$8</td>
        </tr>
        <tr>
          <th scope="row">Feet</th>
          <td>$15</td>
        </tr>
        <tr>
          <th colSpan={2} className="has-text-centered">UV Gel</th>
        </tr>
        <tr>
          <th scope="row">Color Own Nails</th>
          <td>$40</td>
        </tr>
        <tr>
          <th scope="row">Filling</th>
          <td>$40</td>
        </tr>
        <tr>
          <th scope="row">Extension</th>
          <td>$80</td>
        </tr>
        <tr>
          <th colSpan={2} className="has-text-centered">Permanent French</th>
        </tr>
        <tr>
          <th scope="row">Own Nails</th>
          <td>$70</td>
        </tr>
        <tr>
          <th scope="row">Extension</th>
          <td>$90</td>
        </tr>
        <tr>
          <th scope="row">With Optional UV Top Coat</th>
          <td>$7</td>
        </tr>
        <tr>
          <th scope="row">Filling Regular</th>
          <td>$30</td>
        </tr>
        <tr>
          <th scope="row">Filling French Line</th>
          <td>$40</td>
        </tr>
        </tbody>
      </table>
    );
  }
}

export default NailPrices;
