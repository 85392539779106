import React, { Component } from 'react';

class HairPrices extends Component {
  render() {
    return (
      <table className="table">
        <tbody>
        <tr>
          <th scope="row">Haircut &nbsp;<i className="fas fa-male"></i></th>
          <td>$45&ndash;50</td>
        </tr>
        <tr>
          <th scope="row">Haircut &nbsp;<i className="fas fa-female"></i></th>
          <td>$70&ndash;80</td>
        </tr>
        <tr>
          <th scope="row">Blow Dry</th>
          <td>$45&ndash;55</td>
        </tr>
        <tr>
          <th scope="row">Up-Do</th>
          <td>$80&ndash;120</td>
        </tr>
        </tbody>
      </table>
    );
  }
}

export default HairPrices;
