import React, { Component } from 'react';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: '',
      email: '',
      message: '',
      sent: false,
      error: false
    };
  }

  onCustomerChange = (event) => {
    this.setState({customer: event.target.value});
  }

  onEmailChange = (event) => {
    this.setState({email: event.target.value});
  }

  onMessageChange = (event) => {
    this.setState({message: event.target.value});
  }

  sendQuestion = () => {
    window.ga('send', 'event', 'Send us a question', 'click');

    const url = 'https://formspree.io/isalonnyc@gmail.com';

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        customer: this.state.customer,
        email: this.state.email,
        message: this.state.message,
        _subject: 'New question from isalonnyc.com!'
      }),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    })
    .then(res => res.json())
    .then((response) => {
      const success = response.ok;
      this.setState({sent: success, error: !success});
    }, (error) => {
      this.setState({error: true});
    })
  };

  render() {
    return (
      <div className="box">
        <div className="content">
          <h1 className="title">Hello, I have a question.</h1>
        </div>
        <div className="field">
          <div className="control has-icons-left">
            <input className="input" type="text" placeholder="Your name" value={this.state.customer} onChange={this.onCustomerChange} disabled={this.state.sent} />
            <span className="icon is-small is-left"><i className="fas fa-user"></i></span>
          </div>
        </div>
        <div className="field">
          <div className="control has-icons-left">
            <input className="input" type="email" placeholder="Your email so we can reply back to you" value={this.state.email} onChange={this.onEmailChange} disabled={this.state.sent} />
            <span className="icon is-small is-left"><i className="fas fa-envelope"></i></span>
          </div>
        </div>
        <div className="field">
          <div className="control">
            <textarea className="textarea" placeholder="Anything related to your hair, appointment, comments, etc." value={this.state.message} onChange={this.onMessageChange} disabled={this.state.sent}></textarea>
          </div>
        </div>
        {
          !this.state.sent &&
          <div className="field is-grouped">
            <div className="control">
              <button className="button is-link" onClick={() => this.sendQuestion()} disabled={this.state.sent}>Send</button>
            </div>
          </div>
        }
        {
          this.state.sent &&
          <div className="notification is-success">
            <i className="far fa-check-circle"></i>&nbsp;Your question is on its way! Thanks for sending us a question and we will reply to your email as soon as we can.
          </div>
        }
        {
          this.state.error &&
          <div className="notification is-danger">
            Oops! Your question cannot be sent right now. Sorry, we are having some technical difficulties.
          </div>
        }
      </div>
    );
  }
}

export default ContactForm;
