import React, { Component } from 'react';
import PricingTable from '../PricingTable';

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0
    };
  }
  
  changeTab = (index) => {
    this.setState({tab: index});
  };

  render() {
    return (
      <div className="box">
        <div className="content">
          <h1 className="title">Browse our beauty services for hair, nails, face, and body.</h1>
        </div>
        <div className="tabs">
          <ul>
            <li className={this.state.tab === 0 ? 'is-active' : ''}><a onClick={() => this.changeTab(0)}>Hair</a></li>
            <li className={this.state.tab === 1 ? 'is-active' : ''}><a onClick={() => this.changeTab(1)}>Color</a></li>
            <li className={this.state.tab === 2 ? 'is-active' : ''}><a onClick={() => this.changeTab(2)}>Extensions, Treatments, Others</a></li>
            <li className={this.state.tab === 3 ? 'is-active' : ''}><a onClick={() => this.changeTab(3)}>Waxing</a></li>
            <li className={this.state.tab === 4 ? 'is-active' : ''}><a onClick={() => this.changeTab(4)}>Threading, Eyebrows, Eyelashes</a></li>
            <li className={this.state.tab === 5 ? 'is-active' : ''}><a onClick={() => this.changeTab(5)}>Massage</a></li>
            <li className={this.state.tab === 6 ? 'is-active' : ''}><a onClick={() => this.changeTab(6)}>Nails</a></li>
          </ul>
        </div>
        <PricingTable type={this.state.tab} />
      </div>
    );
  }
}

export default Services;
