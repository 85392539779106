import React, { Component } from 'react';
import './index.css';
import Details from './Details';
import Map from './Map';

class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMap: 0,
      mapUrl: ''
    }
  }

  urls() {
    return [
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.6349477314693!2d-73.98068288457797!3d40.7260520793303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259773f8af199%3A0xec92fed3e36b7050!2sI+Salon!5e0!3m2!1sen!2sus!4v1517095838156',
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.375554186795!2d-74.0142335845784!3d40.70974837933218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2599be182db03%3A0x9139bf7bb983f0d8!2sI+Salon!5e0!3m2!1sen!2sus!4v1517083483681',
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.39485233813!2d-73.99219228457783!3d40.73133637932975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2599be1f6d38f%3A0xa9c3691b6ae98ab2!2s77+E+10th+St%2C+New+York%2C+NY+10003!5e0!3m2!1sen!2sus!4v1517095892391'
    ];
  }

  selectMap = (index) => {
    this.setState({
      selectedMap: index,
      mapUrl: this.urls()[index]
    });
  }

  componentDidMount() {
    this.setState({mapUrl: this.urls()[0]});
  }

  render() {
    return (
      <div className="box">
        <div className="content">
          <h1 className="title is-spaced">And come find us. We are finally open!</h1>
        </div>
        <Details currentMap={this.state.selectedMap} changeMap={this.selectMap} />
        <div className="content has-text-centered Locations-map">
          <Map url={this.state.mapUrl} />
        </div>
      </div>
    );
  }
}

export default Locations;