import React, { Component } from 'react';
import giftcards from './giftcards.jpg';

class GiftCards extends Component {
  render() {
    return (
      <div className="box">
        <article className="media">
          <div className="media-content">
            <div className="content">
              <h1 className="title">Got a Gift Card? Treat yourself, a friend or a loved one.</h1>
              <h3 className="subtitle">If you have any gift cards from Spafinder Wellness 365, SpaWish, or Spa & Wellness, we glady accept!</h3>
            </div>
          </div>
          <div className="media-right is-hidden-mobile">
            <figure className="image">
              <img src={giftcards} alt="gift cards" />
            </figure>
          </div>
        </article>
      </div>
    );
  }
}

export default GiftCards;